import { Link } from '~/components/global/Link';
import clsx from 'clsx';

export type Breadcrumb = {
  /**
   * URL to link to
   */
  href?: string;
  /**
   * Label of the breadcrum item
   */
  label: string;
};

/**
 * Breadcrumbs component for the top of pages.
 */
export function Breadcrumbs({
  items,
  truncateAfter = null,
  className = '',
}: {
  /**
   * Array of items for the breadcrumbs
   */
  items: Breadcrumb[];
  /**
   * On mobile truncate after number of items
   */
  truncateAfter?: number | null;
  /**
   * Additional class names to apply to the root element
   */
  className?: string;
}) {
  return (
    <nav
      aria-label="Breadcrumbs"
      className={clsx('flex overflow-hidden', className)}
    >
      <ol className="inline items-center space-x-1 break-words">
        {items.map((item, index) => (
          <li
            key={index}
            className={clsx(
              'inline items-center',
              {
                "after:mr-1 after:ml-1 after:content-['/']":
                  index < items.length - 1 &&
                  (truncateAfter === null ||
                    (truncateAfter !== null && index >= 0)),
              },
              {
                "first:after:content-[''] first:md:after:content-['/']":
                  index + 1 < items.length &&
                  truncateAfter !== null &&
                  index < truncateAfter,
              },
            )}
          >
            {truncateAfter !== null && truncateAfter === index + 1 && (
              <Link
                href={item.href as string}
                className={'text-blue-600 hover:underline md:hidden'}
              >
                ...
              </Link>
            )}
            {index + 1 < items.length && (
              <Link
                href={item.href as string}
                className={clsx('text-blue-600 hover:underline', {
                  'hidden md:inline':
                    truncateAfter !== null && index < truncateAfter,
                })}
              >
                {item.label}
              </Link>
            )}
            {index + 1 === items.length && <span>{item.label}</span>}
          </li>
        ))}
      </ol>
    </nav>
  );
}
