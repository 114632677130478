import { memo, ReactElement, useState } from 'react';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { CampaignBannerProps } from '~/types/campaign';

/**
 * Campaign banner
 * Used to display a campaign specific banner on the site
 *
 * @param {string} backgroundColor - Background color of the banner.
 * @param {string} fontColor - Font color of the banner.
 * @param {string} bannerContent - Content of the banner.
 * @param {string} bannerBadge - Badge of the banner.
 */
export default memo(function CampaignBanner({
  backgroundColor,
  bannerContent,
  bannerBadge,
  fontColor,
}: CampaignBannerProps): ReactElement {
  const [open, setOpen] = useState(false);

  const bannerContentContainer = (
    <div dangerouslySetInnerHTML={{ __html: bannerContent }} />
  );
  return (
    <div
      role="banner"
      aria-label="Campaign Banner"
      className="appsumo-style-links relative z-1 block w-full py-2 px-3"
      style={{
        backgroundColor,
        color: fontColor ?? 'white',
      }}
      data-testid="campaign-banner-wrapper"
    >
      <div className="flex justify-center">
        <Image
          src={`${bannerBadge}?width=320`}
          alt="Campaign Banner"
          width={90}
          height={20}
          className="!relative my-auto !h-5 !w-auto"
          unoptimized
        />
        <div
          className="hidden pl-2 lg:block"
          data-testid="campaign-banner-desktop-content"
        >
          {bannerContentContainer}
        </div>
        {/* mobile dropdown */}
        <div className="lg:hidden">
          <FontAwesomeIcon
            icon={faChevronDown}
            width="16"
            height="16"
            className={`ml-2 transition duration-300 ease-in-out ${
              open ? 'rotate-180' : ''
            }`}
            data-testid="campaign-banner-button"
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
      {open && (
        <div
          className="text-center transition duration-300 ease-in-out lg:hidden"
          data-testid="campaign-banner-mobile-content"
        >
          {bannerContentContainer}
        </div>
      )}
    </div>
  );
});
