import { Deal, StageDetails } from '~/types/deal';
import { APPSUMO_PLUS_DEAL_ID, DEAL_STAGE } from '~/lib/util/constants';

export const getPlanTypeText = (unique_plan_types: (string | null)[][]) => {
  let textStr = '';

  for (let i = 0; i < unique_plan_types.length; i += 1) {
    const typeArr = unique_plan_types[i];

    if (typeArr[0] === 'One Time Purchase') {
      textStr += 'Lifetime deal';
    } else if (typeArr[0] === 'Limited License') {
      textStr += `${typeArr[2]} ${typeArr[1]} DEAL`;
    } else {
      textStr += typeArr[0];
    }

    if (i < unique_plan_types.length - 1) {
      textStr += ' & ';
    }
  }

  return textStr;
};

export const isDealInactiveIndefinitely = (
  stage_details: StageDetails,
): boolean => {
  return (
    stage_details.current_stage === DEAL_STAGE.INACTIVE &&
    stage_details.next_stage === null
  );
};

export const skipQuantityRemaining = (deal: Deal): boolean => {
  return (
    deal.id === APPSUMO_PLUS_DEAL_ID ||
    !!deal.buy_button?.is_ended ||
    (deal.uses_codes &&
      (!deal.shows_codes_remaining ||
        !deal.codes_remaining ||
        deal.codes_remaining <= 0 ||
        deal.codes_remaining > deal.active_codes)) ||
    (deal.uses_limited_licensing &&
      (!deal.limited_licenses_remaining ||
        deal.limited_licenses_remaining <= 0 ||
        deal.limited_licenses_remaining > deal.limited_licenses_quantity))
  );
};
