import { useState, useRef, ReactElement } from 'react';
import { ArrowContainer, Popover as TinyPopover } from 'react-tiny-popover';
import { useEventListener } from 'usehooks-ts';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

/**
 * Display a popover (tooltip) when the child is hovered.
 */
export function Popover({
  children,
  content,
  className,
  closeOnScroll = true,
  positions = ['top', 'bottom', 'left', 'right'],
  spacing = 5,
}: {
  children: ReactElement;
  /**
   * Content of the popover (tooltip).
   */
  content: string | ReactElement;
  className?: string;
  /**
   * Should the popover close on a scroll event
   */
  closeOnScroll?: boolean;
  /**
   * Positions to display the popover
   */
  positions?: ('top' | 'bottom' | 'left' | 'right')[];
  spacing?: number;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const childRef = useRef(null);

  useEventListener('mouseover', () => setIsOpen(true), childRef);
  useEventListener('touchstart', () => setIsOpen(true), childRef);

  useEventListener('mouseout', () => setIsOpen(false), childRef);

  useEventListener('scroll', () => {
    if (closeOnScroll) setIsOpen(false);
  });

  // append to main layout to get font styles
  const parentEl =
    typeof document === 'undefined'
      ? undefined
      : (document.getElementById('__next')?.firstChild as HTMLElement);

  return (
    <>
      {/* z-index must be > than z-index set in BrowseFilters.tsx for mobile popover to display */}
      <TinyPopover
        isOpen={isOpen}
        parentElement={parentEl}
        padding={spacing}
        containerClassName="z-[10000] md:z-5"
        positions={positions}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowSize={10}
            arrowColor={'rgb(9 41 43 / 1)'}
            arrowStyle={{ bottom: 1 }}
          >
            <Transition
              show={isOpen}
              enter="transition ease-in duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className={clsx(
                'z-[10000] max-w-[230px] rounded border bg-forest p-2 text-center font-sans text-sm text-white shadow-lg md:z-5',
                className,
              )}
            >
              <div data-testid="popover-content">{content}</div>
            </Transition>
          </ArrowContainer>
        )}
        ref={childRef}
      >
        {children}
      </TinyPopover>
    </>
  );
}
