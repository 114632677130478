import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

/**
 * Display a date strig using the specified format handling Server to Client.
 */
export function DateFormat({ date, format }: { date: string; format: string }) {
  const [dateString, setDateString] = useState('');

  // update string to local time
  useEffect(() => {
    setDateString(dayjs(new Date(date)).format(format));
  }, [date, format]);

  return <>{dateString}</>;
}
