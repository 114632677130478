import { createElement, ReactNode } from 'react';

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export function Heading({
  size = 'h1',
  className = '',
  children,
  ...props
}: {
  /**
   * Size of the heading (preferably use Heading.HX directly)
   */
  size: HeadingType;
  /**
   * Additional class names
   */
  className?: string;
  children: ReactNode;
}) {
  let classNames = '';

  switch (size) {
    case 'h1':
      classNames = 'text-3xl font-bold';
      break;

    case 'h2':
      classNames = 'text-2xl font-bold';
      break;

    case 'h3':
      classNames = 'text-xl font-bold';
      break;

    case 'h4':
      classNames = 'text-lg font-semibold';
      break;

    case 'h5':
      classNames = 'text-base font-semibold';
      break;

    case 'h6':
      classNames = 'text-sm font-semibold';
      break;
  }

  return createElement(
    size,
    { ...props, className: `font-header ${classNames} ${className}` },
    children,
  );
}

Heading.H1 = function H1(props: any) {
  return <Heading size="h1" {...props} />;
};

Heading.H2 = function H2(props: any) {
  return <Heading size="h2" {...props} />;
};

Heading.H3 = function H3(props: any) {
  return <Heading size="h3" {...props} />;
};

Heading.H4 = function H4(props: any) {
  return <Heading size="h4" {...props} />;
};

Heading.H5 = function H5(props: any) {
  return <Heading size="h5" {...props} />;
};

Heading.H6 = function H6(props: any) {
  return <Heading size="h6" {...props} />;
};
