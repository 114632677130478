import { CSSProperties, useContext, useMemo } from 'react';
import { GlobalContext } from '~/contexts/global';
import { getActiveCampaignForDeal } from '~/lib/campaigns/utils';
import { Deal } from '~/types/deal';

function useDealCampaign(deal: Deal) {
  const {
    campaigns: { campaignConfigs },
  } = useContext(GlobalContext);

  const dealActiveAutoCampaign = useMemo(
    () =>
      getActiveCampaignForDeal({
        campaignConfigs,
        dealParticipation: deal.participation,
      }),
    [deal, campaignConfigs],
  );

  const isCampaignBanner = deal.banner_details?.svg === 'campaign';
  const backgroundColor =
    dealActiveAutoCampaign?.campaign_config?.banner_color || '';
  const bannerStyles = {} as CSSProperties;

  if (dealActiveAutoCampaign && isCampaignBanner) {
    bannerStyles.background = backgroundColor;
  }

  const campaignBannerContent =
    dealActiveAutoCampaign?.campaign_config?.banner_content || '';

  return {
    dealActiveAutoCampaign,
    campaignBannerContent,
    backgroundColor,
  };
}

export default useDealCampaign;
