import { ButtonHTMLAttributes, ReactNode, createElement } from 'react';
import clsx from 'clsx';

import { Link } from '~/components/global/Link';

type ButtonPrimaryType = {
  /**
   * Primary button style
   */
  primary: boolean;
  secondary?: never;
  tertiary?: never;
};

type ButtonSecondaryType = {
  primary?: never;
  /**
   * Secondary button style
   */
  secondary: boolean;
  tertiary?: never;
  outline?: boolean;
};

type ButtonTertiaryType = {
  primary?: never;
  secondary?: never;
  /**
   * Tertiary button style
   */
  tertiary?: boolean;
  outline?: never;
};

type ButtonPropsType = {
  size?: 'small' | 'medium' | 'large';
  href?: string;
  className?: string;
  children: ReactNode;
};

type ButtonType = (
  | ButtonPrimaryType
  | ButtonSecondaryType
  | ButtonTertiaryType
) &
  ButtonPropsType &
  ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({
  primary,
  secondary,
  tertiary,
  href,
  size = 'medium',
  children,
  className = '',
  ...props
}: ButtonType) {
  const cls = clsx(
    {
      'border-2 border-transparent bg-dorado text-midnight hover:border-lemon hover:bg-lemon focus:border-black-pearl focus:bg-lemon active:border-sunset active:bg-sunset disabled:bg-dorado/40 disabled:text-midnight/70 disabled:hover:bg-dorado/40 disabled:active:border-transparent disabled:focus:border-transparent':
        primary || (!secondary && !tertiary),
      'border-2 border-transparent bg-deep-ocean text-white hover:border-skys-the-limit hover:bg-skys-the-limit hover:text-midnight focus:border-black-pearl focus:bg-bolt focus:text-midnight active:border-bolt active:bg-bolt active:text-white':
        secondary,
      'border-2 border-deep-ocean text-deep-ocean hover:bg-skys-the-limit focus:border-black-pearl focus:bg-skys-the-limit focus:text-midnight active:border-deep-ocean active:bg-bolt active:text-white':
        tertiary,
    },
    {
      'px-4 py-1 text-sm font-semibold': size === 'small',
      'px-6 py-2 font-bold': size === 'medium',
      'px-8 py-3 text-lg font-bold': size === 'large',
    },
    'rounded-full font-header',
    className,
  );

  // if href was passed we use Link to render the button
  if (href) {
    return createElement(
      Link,
      {
        href: `${href}`,
        className: cls,
      },
      children,
    );
  }

  return createElement('button', { ...props, className: cls }, children);
}
