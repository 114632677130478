import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

/**
 * Spinning loader using Font Awesome.
 */
export function Loader({
  size = '7xl',
  className = '',
}: {
  /**
   * Size of the spinner (corresponds to text-<size> in Tailwind)
   */
  size?:
    | 'sm'
    | 'base'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl';
  className?: string;
}) {
  // Tailwind classes for each size, doesn't work correctly with dynamic sizes (ie. 'text-${size}')
  const sizeClasses = {
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
    '5xl': 'text-5xl',
    '6xl': 'text-6xl',
    '7xl': 'text-7xl',
  };

  return (
    <div>
      <FontAwesomeIcon
        icon={faSpinner}
        spin
        className={clsx(sizeClasses[size], className)}
      />
    </div>
  );
}
